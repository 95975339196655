:root {
  --black: #1e1e1e;
  --white: #FFFFFF;
  --white50: #FFFFFF80;
  --white10: #FFFFFF1A;
  --black50: #1e1e1e80;
  --black10: #1e1e1e1A;


  --ff-base: "satoshi-Variable", sans-serif;

  --fw-regular: 400;
  --fw-bold: 800;
  --fw-heavy: 900;

  --fs-400: 1.125rem;
  --fs-500: 1.5rem;
  --fs-800: 4rem;
}

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('./fonts/Satoshi-Variable.woff2') format('woff2'),
       url('./fonts/Satoshi-Variable.woff') format('woff'),
       url('./fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

h1 {
  color: var(--white);
  font-size: var(--fs-800);
  font-family: var(--ff-base);
  font-weight: var(--fw-heavy);
  line-height: normal;
}

h2 {
  color: var(--white);
  font-size: var(--fs-400);
  font-family: var(--ff-base);
  font-weight: var(--fw-regular);
  line-height: normal;
}

h3 {
  color: var(--white);
  font-size: var(--fs-400);
  font-family: var(--ff-base);
  font-weight: var(--fw-bold);
  line-height: normal;
}

p {
  color: var(--white);
  font-size: var(--fs-400);
  font-family: var(--ff-base);
  font-weight: var(--fw-regular);
  line-height: normal;
  margin-bottom: 1rem;
}

li {
  color: var(--black50);
  font-size: var(--fs-400);
  font-family: var(--ff-base);
  font-weight: var(--fw-bold);
  line-height: normal;
  list-style: none;
}

html {
  color-scheme: dark;
  cursor: none;
}

body {
  display: flex;
  flex-direction:column;
  background-color: var(--black);
  background-image:url(images/background-dots.svg);
  background-attachment: fixed;
  box-sizing: border-box;
}

.cursor-dot {
  width: 5px;
  height: 5px;
  background-color: var(--black);
  opacity: 0;
}

.cursor-outline {
  width: 5px;
  height: 5px;
  border: 2px solid var(--black);
}

.cursor-dot,
.cursor-outline {
  position: fixed;
  top: 0px;
  left: 0px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1;
  pointer-events: none;
  mix-blend-mode: difference;
  filter: invert(100);

}

.scrolling-section {
  width: 100%;
  box-sizing: border-box;
}

.header {
  background-image:url(images/header-image.png) ;
  background-repeat:no-repeat;
  display: inline-flex;
  height: calc(100vh - 50px);
  width: 100vw;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  background-position: left, top;
  box-sizing: border-box;
}

.nav-bar {
  display: flex;
  width: 100%;
  padding: 1.5rem 5rem;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  mix-blend-mode: difference;
  filter: invert(100);
  z-index: 1;

}

.logo {
  mix-blend-mode: difference;
  filter: invert(100);
}

.navigation {
  margin-top: 20px;
}

.navigation ul {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.navigation li {
  display: inline-block;
  margin-right: 10px;
}

.navigation li a {
  color: var(--black);
  font-size: 1.125rem;
  font-family: Satoshi Variable;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.navigation li a:hover {
  text-decoration: underline;
}

.logo {
  filter: invert(100%);
}

.content {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  max-width: 600px;
  gap: 0.5rem;
  position: relative;
  padding-left: 80px;
}

.content > * {
  position: relative;
}

.content > *:not(:first-child) {
  top: -4rem;
}

.header-title {
  display: flex;
  width: 37.4375rem;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.header-title > * {
  position: relative;
}

.header-title > *:not(:first-child) {
  top: -4rem;
}


.projects {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  gap: 2.5rem;
  list-style: none;
  box-sizing: border-box;
  padding: clamp(2rem, 4vw, 5rem);
}

.projectcard {
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100%;
  max-width: 1200px;
  padding: clamp(2rem, 4vw, 5rem);
  margin-bottom: 0rem; /* Add margin between project cards */
  border-radius: 1.5rem;
  background: var(--content, #FFF);
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  gap: clamp(2rem, 2vw, 8rem);
  color: var(--black);
  transform: scale(1);
  transition: transform 3s ease;
}

.projectcard:hover {
  transform: scale(1.02);
}


.cardcontent {
  
    display: flex;
    color: var(--black);
    flex-direction: column;
    width: clamp(8rem, 80vw, 21rem);
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    justify-content: space-between;
}

.card-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
}

.card-title > * {
  margin: 0;
}

.project-title {
    color: var(--black);
    font-size: clamp(1rem, 1.7vw, 1.7rem);
    font-family: var(--ff-base);
    font-style: normal;
    font-weight: var(--fw-bold);
    line-height: normal;
}

.roles-list {
  padding: 0;
}

.project-body {
  color: var(--black);
  font-size: clamp(1rem, 1.7vw, 1.25rem);
}

.btn-simple {
  display: flex;
  align-items: center;
  background-color: var(--white);
  gap: 0.625rem;
  color: var(--black);
  padding: 8px 8px 16px 0px;
  border-radius: 100px;
  border: 0;
  transition: 0.8s ease;
}

.btn-simple:hover {
  gap: 1.2rem;
}

.button-copy {
  color: var(--black);
  font-size: var(--fs-400);
  font-family: var(--ff-base);
  font-style: normal;
  font-weight: var(--fw-bold);
  line-height: normal;
}

.arrow {
  margin-top: 4px;
}
.card-visual {
  display: flex;
  width:  clamp(16rem, 40vw, 120rem);
  justify-content: flex-end;
}

.card-visual img {
  width:  clamp(16rem, 20vw, 20rem);
}


.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-in-visible {
  opacity: 1;
}

.cursorBlock {
  opacity: 1;
}

.cursorNone {
  opacity: 0;
}
